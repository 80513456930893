<header>
  <app-announcement-bar *ngIf="activeAnnouncements.length > 0" [announcements]="activeAnnouncements"></app-announcement-bar>
</header>
<main role="main">
  <div [style]="calculatePageHeight()" class="f-row main">
    <button class="open-side-bar" (click)="openSideBar()">
      <app-icon icon="menu" class="menu-icon"></app-icon>
    </button>
    <aside role="complementary" aria-label="Side Bar" class="h-100">
      <div class="side-bar-wrapper h-100" [ngClass]="sideBarState">
        <app-side-bar class="side-bar" (shouldCloseSideBar)="closeSideBar($event)"></app-side-bar>
      </div>
    </aside>
    <section role="region" aria-label="Supervisor Content" class="w-100 overflow-y-auto">
      <div class="router" cdk-scrollable>
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
</main>
