import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Subject, takeUntil} from 'rxjs';
import {take} from 'rxjs/operators';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {setStudentExperienceStepCompletionAction} from '../../../../core/store/student-evaluation/student-evaluation.actions';
import {getStudentExperienceEvaluationsSelector} from '../../../../core/store/student-evaluation/student-evaluation.selectors';
import {EvaluationQuestion, EvaluationQuestionSection, EvaluationQuestionType, IEvaluation} from '../../../../shared/model/evaluation-model';
import {IOpportunity} from '../../../../shared/model/opportunity.model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
    selector: 'app-evaluation-experience',
    templateUrl: './evaluation-experience.component.html',
    styleUrls: ['./evaluation-experience.component.scss'],
    standalone: false
})
export class EvaluationExperienceComponent implements OnInit, OnDestroy {
  @Input()
  public service!: IServiceRegistration;

  @Input()
  public opportunity!: IOpportunity;

  public experienceForm!: FormGroup;
  public evaluations: IEvaluation[] = [];
  public hasRetrievedEvals: boolean = false;

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'ST_EXP_1', text: 'Supervisor', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.EXPERIENCE, order: '1' },
    { id: 'ST_EXP_2', text: 'Comments', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.EXPERIENCE, order: '2' },
    { id: 'ST_EXP_3', text: 'Organization', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.EXPERIENCE, order: '3' }
  ];

  private endSubscription$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private serviceRegistrationService: ServiceRegistrationService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.experienceForm = this.fb.group({
      'ST_EXP_1': [null, [Validators.required]],
      'ST_EXP_2': [null, [Validators.required]],
      'ST_EXP_3': [null, [Validators.required]]
    });

    this.watchFormValidity();
    this.getEvaluations();
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public watchFormValidity() {
    this.experienceForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.store.dispatch(setStudentExperienceStepCompletionAction({isComplete: this.experienceForm.valid}));
    })
  }

  public setCurrentRating(value: string, type: string) {
    if(type === 'supervisor'){
      this.experienceForm.get('ST_EXP_1')?.setValue(value);
    } else if (type === 'organization') {
      this.experienceForm.get('ST_EXP_3')?.setValue(value);
    }
  }

  public getEvaluations() {
    this.store.select(getStudentExperienceEvaluationsSelector).pipe(
      take(1)
    ).subscribe(evaluations => {
      this.evaluations = evaluations;

      evaluations.forEach(evaluation => {
        this.experienceForm.get(evaluation.question.id)?.setValue(evaluation.response);
      })

      this.hasRetrievedEvals = true;
    });
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.experienceForm.controls).forEach((question, index) => {
        const value = this.experienceForm.get(question)?.value;
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;
        const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === questionUsed.order);

        if (existingEvaluation && existingEvaluation.response !== value) {
          updatedEvaluations.push({
            ...existingEvaluation,
            response: value
          });
        }
      });

      updatedEvaluations.forEach(evaluation => {
        this.serviceRegistrationService.updateEvaluationsThroughState(this.service.id, evaluation);
      });
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.experienceForm.controls).map((question, index) => {
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;
        const value = this.experienceForm.get(question)?.value;

        return {
          serviceId: this.service.id,
          response: value,
          question: questionUsed
        };
      });

      this.serviceRegistrationService.addEvaluationsThroughState(this.service.id, newEvaluations);
    }
  }

  public getEvaluationRating(id: string) {
    let foundEval = this.evaluations.find(evaluation => evaluation.question.id === id);
    return foundEval ? foundEval.response : null;
  }
}
