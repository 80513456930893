import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-past-evaluation-organization',
    templateUrl: './past-evaluation-organization.component.html',
    styleUrls: ['./past-evaluation-organization.component.scss'],
    standalone: false
})
export class PastEvaluationOrganizationComponent {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public orgType: string = 'nonprofit';

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.minLength(10)]],
      website: [null, Validators.required],
      organizationType: [null, Validators.required],
    });
  }

  setStyle(value: string) {
    if(this.form.get(value)?.invalid && (this.form.get(value)?.dirty || this.form.get(value)?.touched)) {
      return "custom-input-error"
    } else if(this.form.get(value)?.valid) {
      return "custom-input-success"
    }

    return undefined;
  }
}
