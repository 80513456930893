import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUserInfoAction} from './core/store/auth/auth.actions';
import { TitleService } from "./shared/services/title/title.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  public title = 'serve-ui';

  constructor(private store: Store, private _titleService: TitleService) { }


  ngOnInit() {
    this.store.dispatch(getUserInfoAction());
  }
}
