import { Action, createReducer, on } from '@ngrx/store';
import { stateHelperFunctions } from '../stateHelperFunctions';
import {
  addEvaluationsFailureAction,
  addEvaluationsSuccessAction,
  clearStudentEvaluationAction, getEvaluationsAction,
  getEvaluationsFailureAction,
  getEvaluationsSuccessAction,
  setSetStudentEvaluationStepNumberAction,
  setStudentExperienceStepCompletionAction,
  setStudentOpportunityStepCompletionAction,
  setStudentReflectionStepCompletionAction,
  updateEvaluationsFailureAction,
  updateEvaluationsSuccessAction
} from './student-evaluation.actions';
import { IStudentEvaluationState } from './student-evaluation.state';

export const initialStudentEvaluationState: IStudentEvaluationState = {
  evaluations: [],
  isLoadingEvaluations: false,
  isStudentExperienceStepComplete: false,
  isStudentOpportunityStepComplete: false,
  isStudentReflectionStepComplete: false,
  isStudentReviewStepComplete: false,
  studentEvaluationStepNumber: 0,
  error: undefined
};

const studentEvaluationReducer = createReducer(
  initialStudentEvaluationState,

  on(setSetStudentEvaluationStepNumberAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      studentEvaluationStepNumber: action.stepNumber
    })
  ),

  on(setStudentReflectionStepCompletionAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      isStudentReflectionStepComplete: action.isComplete
    })
  ),

  on(setStudentOpportunityStepCompletionAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      isStudentOpportunityStepComplete: action.isComplete
    })
  ),

  on(setStudentExperienceStepCompletionAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      isStudentExperienceStepComplete: action.isComplete
    })
  ),

  on(clearStudentEvaluationAction,
      (state): IStudentEvaluationState => ({
      ...initialStudentEvaluationState
      })
  ),

  on(getEvaluationsAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      isLoadingEvaluations: true
    })
  ),

  on(getEvaluationsSuccessAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      evaluations: action.evaluations,
      isLoadingEvaluations: false
    })
  ),

  on(addEvaluationsFailureAction,
    updateEvaluationsFailureAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      error: action.error
    })
  ),

  on(getEvaluationsFailureAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      isLoadingEvaluations: false,
      error: action.error
    })
  ),

  on(addEvaluationsSuccessAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      evaluations: state.evaluations.concat(action.evaluations)
    })
  ),

  on(updateEvaluationsSuccessAction,
    (state, action): IStudentEvaluationState => ({
      ...state,
      evaluations: stateHelperFunctions.replaceItemInArray(state.evaluations, action.evaluation)
    })
  ),
);

export function studentEvaluationReducers(state: IStudentEvaluationState, action: Action) {
  return studentEvaluationReducer(state, action);
}
