import { Component } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-past-evaluation-reflection',
    templateUrl: './past-evaluation-reflection.component.html',
    styleUrls: ['./past-evaluation-reflection.component.scss'],
    standalone: false
})
export class PastEvaluationReflectionComponent {
  public form: UntypedFormGroup = new UntypedFormGroup({});

}
