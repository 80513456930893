import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.scss'],
    standalone: false
})
export class EvaluationComponent implements OnInit{

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.cdr.detectChanges();
  }

}
