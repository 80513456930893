import {Component, OnDestroy, OnInit} from '@angular/core';
import { GlobalsService } from '../../core/service/globals.service';
import {Subject, takeUntil} from "rxjs";

@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss'],
    standalone: false
})
export class AdminSettingsComponent implements OnInit, OnDestroy {
  public isEditingLimit: boolean = false;
  public updatedMaxVolunteerCount: number = 50;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private globalsService: GlobalsService) {}

  ngOnInit(): void {
    this.loadInitialCount();
  }

  public toggleEditing(): void {
    this.isEditingLimit = !this.isEditingLimit;
    if (!this.isEditingLimit) this.loadInitialCount();
  }

  public save(): void {
    this.globalsService.setMaxVolunteerCount(this.updatedMaxVolunteerCount.toString()).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.isEditingLimit = false;
      this.loadInitialCount();
    })
  }

  private loadInitialCount(): void {
    this.globalsService.getMaxVolunteerCount().pipe(takeUntil(this.onDestroy$)).subscribe(
      count => this.updatedMaxVolunteerCount = count
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
