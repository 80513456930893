import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EvaluationFeedbackComponent} from './evaluation-feedback/evaluation-feedback.component';
import {EvaluationStepperComponent} from './evaluation-stepper-pages/evaluation-stepper.component';
import {EvaluationComponent} from './evaluation.component';
import {PastEvaluationStepperComponent} from './past-evaluation-stepper-pages/past-evaluation-stepper.component';
import {
  ServiceEvaluationStepperComponent
} from './service-evaluation-stepper-pages/service-evaluation-stepper.component';

const routes: Routes = [
  {
    path: '',
    data: { title: '' },
    component: EvaluationComponent,
    children: [
      {
        path: 'survey/:serviceId',
        data: { title: 'Evaluation' },
        component: EvaluationStepperComponent
      },
      {
        path: 'past-survey',
        data: { title: 'Past Evaluation' },
        component: PastEvaluationStepperComponent
      },
      {
        path: 'feedback',
        data: { title: 'Feedback' },
        component: EvaluationFeedbackComponent
      },
      {
        path: ':serviceId',
        data: { title: 'Service Evaluation' },
        component: ServiceEvaluationStepperComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EvaluationRoutingModule { }
