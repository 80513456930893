import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { EMPTY, filter, Subject, switchMap } from 'rxjs';
import { AnnouncementService } from '../../core/service/announcement.service';
import { AuthService } from '../../core/service/auth.service';
import { OrganizationService } from '../../core/service/organization.service';
import { SupervisorService } from '../../core/service/supervisor.service';
import { AnnouncementAudience, AnnouncementStatus, IAnnouncement } from '../../shared/model/announcement.model';
import { IOpportunity, OpportunityStatus } from '../../shared/model/opportunity.model';
import { IOrganization, OrganizationStatus } from '../../shared/model/organization.model';
import { ISupervisor } from "../../shared/model/supervisor.model";
import { take } from "rxjs/operators";
import { debounce as Debounce } from '../../shared/decorators/debounceDecorator'

@Component({
    selector: 'app-supervisor-landing-page',
    templateUrl: './supervisor-landing-page.component.html',
    styleUrls: ['./supervisor-landing-page.component.scss'],
    standalone: false
})
export class SupervisorLandingPageComponent implements OnInit, OnDestroy {
  private endSubscriptions$ = new Subject();

  //TODO: Update to be false once we have made the impersonate bar not always active
  public isImpersonating: boolean | undefined = true;
  public activeAnnouncements: IAnnouncement[] = [];
  public opportunities: IOpportunity[] = [];
  public organizations: IOrganization[] = [];
  public isSideBarOpen: boolean | undefined = undefined;

  private oneTimeNavigationComplete!: boolean;

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onResize() {
    this.resetSideBarState();
  }

  constructor(
    private supervisorService: SupervisorService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    public announcementService: AnnouncementService,
    private router: Router
  ) {}

  ngOnInit() {
    this.oneTimeNavigationComplete = false;

    if (!this.router.url.includes('supervisor-contract')) {
      this.supervisorService.getSupervisedOpportunitiesFromCurrentUser().pipe(
        filter(Boolean),
        take(1),
        switchMap((opportunities: IOpportunity[]) => {
          this.opportunities = opportunities
            .filter(opportunity => opportunity.status == OpportunityStatus.ACTIVE);

          if (this.opportunities.length > 0) {
            this.router.navigate(['/', 'supervisor', 'my-opportunities', this.opportunities[0].id]);
            return EMPTY;
          } else {
            return this.supervisorService.getCurrentSupervisor().pipe(
              switchMap((supervisor: ISupervisor) => {
                return this.organizationService.getOrganizationsFromLeadId(supervisor.id);
              }),
              switchMap((organizations: IOrganization[]) => {
                this.organizations = organizations.filter(organization => organization.status === OrganizationStatus.ACTIVE);

                if (this.organizations.length > 0 && !this.oneTimeNavigationComplete) {
                  this.oneTimeNavigationComplete = true;
                  this.router.navigate(['/', 'supervisor', 'my-organizations', this.organizations[0].id]);
                } else if (!this.organizations.length) {
                  this.router.navigate(['/', 'supervisor', 'supervisor-no-organization']);
                }
                return EMPTY;
              })
            );
          }
        })
      ).subscribe()
    }

    this.getAnnouncementInformation();

    this.router.events.subscribe( event => {
      event instanceof NavigationEnd || event instanceof NavigationError
        ? this.closeSideBar(true) : void 0;
    });
  }

  ngOnDestroy() {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.complete();
  }

  private getAnnouncementInformation() {
    this.authService.isImpersonating().pipe(
      switchMap((isImpersonating: boolean | undefined) => {
        this.isImpersonating = isImpersonating;

        return this.announcementService.getAdminAnnouncements();
      }),
      switchMap((announcements: IAnnouncement[]) => {
        this.activeAnnouncements = announcements.filter(announcement => {
          return announcement.status === AnnouncementStatus.ACTIVE &&
            (announcement.audience === AnnouncementAudience.GLOBAL || announcement.audience === AnnouncementAudience.SUPERVISOR) &&
            new Date(announcement.startDate) < new Date() &&
            new Date(announcement.endDate) > new Date();
        });

        return EMPTY;
      })
    ).subscribe()
  }

  public calculatePageHeight() {
    const impersonateBarHeight = this.isImpersonating ? 48 : 0;
    const announcementBarHeight = this.activeAnnouncements.length > 0 ? 56 : 0;
    const maxHeight = `calc(100vh - ${impersonateBarHeight}px - ${announcementBarHeight}px)`;
    return `height: ${maxHeight}`;
  }

  public openSideBar(): void {
    this.isSideBarOpen = true;
  }

  public closeSideBar($shouldClose: boolean): void {
    this.isSideBarOpen === undefined || !$shouldClose ? void 0 : this.isSideBarOpen = false;
  }

  public get sideBarState(): string {
    switch (true) {
      case this.isSideBarOpen === undefined:    return '';
      case this.isSideBarOpen === true:         return '--expanded';
      case this.isSideBarOpen === false:        return '--collapsed';
      default:                                  return '';
    }
  }

  private get isMobile(): boolean {
    return window.innerWidth < 720;
  }

  private resetSideBarState(): void {
    if (this.isMobile) return void 0;
    this.isSideBarOpen = undefined;
  }


}
