import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter, map, Observable, Subject, Subscription, takeUntil} from 'rxjs';
import {OrganizationService} from '../../core/service/organization.service';
import {selectOrganizationId} from '../../core/store/router/router.selectors';
import {IOpportunity, OpportunityStatus} from '../../shared/model/opportunity.model';
import {IOrganization} from '../../shared/model/organization.model';
import {ISupervisor} from "../../shared/model/supervisor.model";
import {IModalData, ModalService} from "../../shared/components/modal/modal.service";
import {
  NewSupervisorModalComponent
} from "../../shared/components/modal/modal-views/new-supervisor-modal/new-supervisor-modal.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SupervisorService} from "../../core/service/supervisor.service";
import {take} from "rxjs/operators";
import { debounce as Debounce } from '../../shared/decorators/debounceDecorator';

@Component({
    selector: 'app-organization-config',
    templateUrl: './organization-config.component.html',
    styleUrls: ['./organization-config.component.scss'],
    standalone: false
})
export class OrganizationConfigComponent implements OnInit, OnDestroy {
  public currentTab: string = 'opportunities';
  public organization!: IOrganization;
  public opportunities: IOpportunity[] = [];
  public activeOpportunities: IOpportunity[] = [];
  public isAdmin: boolean = false;
  public pageType: string = 'supervisor';
  public isMobile: boolean = this.checkIfMobile;
  private orgId!: string;

  private endSubscription$ = new Subject();
  public isLeadSupervisor$: Observable<boolean> = new Observable<boolean>();
  private orgSubscription: Subscription | undefined;


  @HostListener('window:resize', ['$event'])
  @Debounce()
  onResize() {
    this.checkIfMobile;
  }

  constructor(public router: Router,
              private organizationService: OrganizationService,
              private store: Store,
              private modalService: ModalService,
              private supervisorService: SupervisorService) { }

  ngOnInit() {
    this.store.select(selectOrganizationId).pipe(
      filter(Boolean),
      takeUntil(this.endSubscription$))
      .subscribe(orgId => {
        this.orgId = orgId;

        if (!!this.orgSubscription) {
          this.orgSubscription.unsubscribe();
        }

        this.orgSubscription = this.organizationService.watchOrganization(this.orgId)
          .pipe(takeUntil(this.endSubscription$))
          .subscribe(organization => this.organization = organization);

        this.organizationService.getOrganizationOpportunities(this.orgId)
          .subscribe(opps => {
            this.opportunities = opps;
            this.activeOpportunities = opps.filter(opp => opp.status === OpportunityStatus.ACTIVE);
          });
      });

    this.isAdmin = this.router.url.includes('admin');
    if (this.isAdmin) {
      this.pageType = 'admin';
    }

    this.isLeadSupervisor$ = this.supervisorService.getCurrentSupervisor().pipe(map(s => this.organization.supervisorId === s.id))
  }

  ngOnDestroy() {
    this.endSubscription$.next(true);
    this.endSubscription$.complete();
  }

  public isActive(tabName: string): string {
    this.currentTab = this.router.url.includes('opportunities') ? 'opportunities' : 'supervisors';

    return tabName == this.currentTab ? 'active-tab' : '';
  }

  public openNewSupervisorModal() {
    const data: IModalData = {
      organization: this.organization,
      opportunities: this.activeOpportunities
    };
    const newSupervisorModal: NgbModalRef = this.modalService.open(NewSupervisorModalComponent, data) as NgbModalRef;

    newSupervisorModal.componentInstance!.newSupervisorEvent
      .subscribe((supervisor: ISupervisor) => {
        this.updateOrganizationWith(supervisor);
        newSupervisorModal.close();
      });
  }

  private updateOrganizationWith(supervisor: ISupervisor): void {
    const orgWithUpdates: IOrganization = {
      ...this.organization,
      subSupervisors: [ ...this.organization.subSupervisors, supervisor.id ]
    };

    this.organizationService.updateOrganization(orgWithUpdates).pipe(take(1)).subscribe();
  }

  private get checkIfMobile(): boolean {
    return this.isMobile = window.innerWidth < 720;
  }
}
