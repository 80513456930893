import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-past-evaluation-experience',
    templateUrl: './past-evaluation-experience.component.html',
    styleUrls: ['./past-evaluation-experience.component.scss'],
    standalone: false
})
export class PastEvaluationExperienceComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({});

  public value!: string;


  constructor(private fb: UntypedFormBuilder) {}


  ngOnInit(): void {
    this.form = this.fb.group({
    });
  }

  public setCurrentRating(value: string) {
    this.value = value;
  }
}
