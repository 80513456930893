import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Answer, IServiceRegistration, ServiceEvalStatus} from "../../../../shared/model/service-registration.model";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ServiceRegistrationService} from "../../../../core/service/service-registration.service";
import {map, Subject, takeUntil} from "rxjs";
import {
  EvaluationQuestion,
  EvaluationQuestionSection,
  EvaluationQuestionType,
  IEvaluation
} from "../../../../shared/model/evaluation-model";

@Component({
    selector: 'app-service-evaluation-hours',
    templateUrl: './service-evaluation-hours.component.html',
    styleUrl: './service-evaluation-hours.component.scss',
    standalone: false
})

export class ServiceEvaluationHoursComponent implements OnInit, OnDestroy {
  @Input()
  public service!: IServiceRegistration;

  @Output()
  public evaluationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public correctionEvent: EventEmitter<any> = new EventEmitter<any>();

  public form: UntypedFormGroup = new UntypedFormGroup({});
  public evaluations: IEvaluation[] = [];
  private endSubscription$ = new Subject();

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'SU_HRS_1', text: 'Hours Verified', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.HOURS, order: '1' },
    { id: 'SU_HRS_2', text: 'Issues', type: EvaluationQuestionType.SUPERVISOR, section: EvaluationQuestionSection.HOURS, order: '2' },
  ];

  constructor(private fb: FormBuilder, private serviceRegistrationService: ServiceRegistrationService) {}

  ngOnInit() {
    this.form = this.fb.group({
      Answer: [null, Validators.required],
      Issues: [null]
    });

    this.getEvaluations();

    this.form.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.evaluationComplete.emit(this.form.valid);
    });

    this.form.get('Answer')?.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe((newAnswerValue) => {
      const issueTextControl = this.form.get('Issues');

      if (newAnswerValue === Answer.NO) {
        issueTextControl?.setValidators(Validators.required);
      } else {
        issueTextControl?.setValidators(null);
      }

      issueTextControl?.updateValueAndValidity();
    });

  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.serviceRegistrationService.getEvaluations(this.service.id).pipe(
      map(evaluations => evaluations.filter(evaluation => evaluation.question.type === EvaluationQuestionType.SUPERVISOR && evaluation.question.section === EvaluationQuestionSection.HOURS)))
      .subscribe(filteredEvals => {
        this.evaluations = filteredEvals;
        filteredEvals.forEach(evaluation => {
          if(evaluation.question.order === '1') {
            this.form.get('Answer')?.setValue(evaluation.response);
          } else if (evaluation.question.order === '2') {
            this.form.get('Issues')?.setValue(evaluation.response);
          }
        })
      })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      //   update eval
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.form.controls).forEach((question, index) => {
        const value = this.form.get(question)?.value;

        if(value !== null && value !== undefined && value !== ''){
          const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === this.evaluationQuestions[index].order);

          if (existingEvaluation) {
            existingEvaluation.response = value;
            updatedEvaluations.push(existingEvaluation);
          } else if (question == 'Issues') {
            this.addNewIssue(question, index, value);
          }
        }
      });

      if(updatedEvaluations.length > 0) {
        updatedEvaluations.forEach(evaluation => {
          this.serviceRegistrationService.updateEvaluations(this.service.id, evaluation).subscribe();
        });
      }
    } else {
      //   new eval
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.form.controls)
        .filter(question => {
          const control = this.form.get(question);
          const value = control?.value;

          return !(value === null || value === undefined);
        })
        .map((question, index) => {
          const value = this.form.get(question)?.value;

          return {
            serviceId: this.service.id,
            response: value,
            question: {
              id: this.evaluationQuestions[index].id,
              order: this.evaluationQuestions[index].order,
              section: EvaluationQuestionSection.HOURS,
              text: this.evaluationQuestions[index].text,
              type: EvaluationQuestionType.SUPERVISOR
            }
          };
        });

      this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
    }

    this.updateServiceRegistrationSupervisorEvalStatus(this.form.get('Answer')?.value);

    if (this.form.get('Answer')?.value === Answer.NO) {
      this.correctionEvent.emit({answer: this.form.get('Answer')?.value, issues: this.form.get('Issues')?.value})
    }
  }

  public addNewIssue(question: string, index:number, value:string) {
    let newEvaluation: Partial<IEvaluation> = {
      serviceId: this.service.id,
      response: value,
      question: {
        id: this.evaluationQuestions[index].id,
        order: this.evaluationQuestions[index].order,
        section: EvaluationQuestionSection.HOURS,
        text: question,
        type: EvaluationQuestionType.SUPERVISOR
      }
    }
    let newEvaluations: Partial<IEvaluation>[] = [];
    newEvaluations.push(newEvaluation);

    this.serviceRegistrationService.addEvaluations(this.service.id, newEvaluations).subscribe();
  }

  public updateServiceRegistrationSupervisorEvalStatus(value: Answer) {

      if (value == Answer.NO) {
        let serviceUpdate: IServiceRegistration = {
          ...this.service,
          supervisorEvalStatus: ServiceEvalStatus.REJECTED,
          studentEvalStatus: ServiceEvalStatus.INCOMPLETE
        }
        this.serviceRegistrationService.updateServiceRegistration(serviceUpdate).subscribe();
      } else if (value == Answer.YES && this.service.supervisorEvalStatus == ServiceEvalStatus.REJECTED) {
        let serviceUpdate: IServiceRegistration = {
          ...this.service,
          supervisorEvalStatus: ServiceEvalStatus.INCOMPLETE,
        }
        this.serviceRegistrationService.updateServiceRegistration(serviceUpdate).subscribe();
      }
  }

  protected readonly Answer = Answer;
}
