import { Component } from '@angular/core';

@Component({
    selector: 'app-evaluation-feedback',
    templateUrl: './evaluation-feedback.component.html',
    styleUrls: ['./evaluation-feedback.component.scss'],
    standalone: false
})
export class EvaluationFeedbackComponent {

}
