import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SupervisorRepository} from '../../core/repository/supervisor.repository';
import {OrganizationRepository} from '../../core/repository/organization.repository';
import {ILogMessage} from "../../shared/model/log-message.model";
import {LogMessageService} from "../../core/service/logMessage.service";
import {catchError, interval, of, Subject, switchMap, takeUntil, tap} from "rxjs";
import {OpportunityRepository} from "../../core/repository/opportunity.repository";
import {ServiceRegistrationRepository} from "../../core/repository/service-registration.repository";

@Component({
    selector: 'app-uploads',
    templateUrl: './uploads.component.html',
    styleUrls: ['./uploads.component.scss'],
    standalone: false
})
export class UploadsComponent implements OnInit, OnDestroy {
  public selectedFile: File | null = null;
  public formData: FormData = new FormData();
  public fileExtension: string | undefined;
  public logMessages: ILogMessage[] = [];
  public isRunning = false;

  private endSubscription$ = new Subject();

  constructor(private supervisorRepository: SupervisorRepository,
              private organizationRepository: OrganizationRepository,
              private opportunityRepository: OpportunityRepository,
              private serviceRepository: ServiceRegistrationRepository,
              private logMessageService: LogMessageService,
              private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.getLogMessage();
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public onFileSelected(event: any) {
    const inputElement = event.target as HTMLInputElement;
    this.selectedFile = (inputElement.files as FileList)[0];
  }

  public uploadFile(repository: 'supervisor' | 'organization' | 'opportunity' | 'service'): void {
    let selectedRepository;

    switch (repository) {
      case 'supervisor' :
        selectedRepository = this.supervisorRepository;
        break;
      case 'organization':
        selectedRepository = this.organizationRepository;
        break;
      case 'opportunity':
        selectedRepository = this.opportunityRepository;
        break;
      case 'service':
        selectedRepository = this.serviceRepository;
        break;
      default:
        console.error('Invalid repository type');
        return;
    }

    this.fileExtension = this.selectedFile?.name.split('.').pop()?.toLowerCase();

    this.formData.delete('file');
    if (!this.selectedFile) {
      this._snackBar.open('No file selected', 'OK');
      return;
    }

    if (!this.fileExtension || !['json'].includes(this.fileExtension)) {
      this._snackBar.open('Invalid file type. Please upload a JSON file.', 'OK');
      return;
    }

    this.formData.append('file', this.selectedFile);

    this.isRunning = true;
    selectedRepository.addUploadFile(this.formData).subscribe( response => {
        interval(1000).pipe(
          takeUntil(this.endSubscription$),
          switchMap(() => this.logMessageService.getLogMessages().pipe(
            tap((messages) => this.logMessages = messages),
            catchError((error: Error) => of(error))
          ))
        ).subscribe();
      },
      (error) => {
        this.isRunning = false;
        this._snackBar.open('Error uploading file', 'OK');
        console.error('Error uploading file:', error);
        setTimeout(() => this.endSubscription$.next(true), 1000);
      },
        () => {
        this.isRunning = false;
        this._snackBar.open('File uploaded successfully', 'OK');
        console.log('File uploaded successfully');
        setTimeout(() => this.endSubscription$.next(true), 1000);
      }
    );
  }

  public getLogMessage() {
    this.logMessageService.getLogMessages().subscribe((logMessage: ILogMessage[])=>{
      this.logMessages = logMessage;
    });
  }

}
