import {Component, OnInit} from "@angular/core";

@Component({
    selector: 'app-access-revoked',
    templateUrl: './access-revoked.component.html',
    styleUrls: ['./access-revoked.component.scss'],
    standalone: false
})
export class AccessRevokedComponent {

  constructor() {}
}
