import { Injectable } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private appName: string = this.formatAppName(environment.appName);

  constructor(private title: Title, private router: Router) {

    this.router.events
      .pipe(
        filter((event: any): boolean => event instanceof NavigationEnd),
        map((): string => this.buildTitle(this.router.routerState.root))
      )
      .subscribe((pageTitle: string): void => {
        this.title.setTitle(pageTitle);
      });
  }

  private buildTitle(route: ActivatedRoute, parentTitles: string[] = []): string {
    const routeTitle: string = route.snapshot.data ? route.snapshot.data['title'] : '';
    const titles: string[] = [...parentTitles];

    if (routeTitle) {
      titles.push(routeTitle);
    }

    if (route.firstChild) {
      return this.buildTitle(route.firstChild, titles);
    }

    if (this.isRepeatingTitlesSequentially(titles)) {
      titles.pop();
    }

    return this.formatTitles(titles);
  }

  private formatAppName(appName: string): string {
    return appName
      .replace(/-/g, ' ')
      .replace('lu', 'LU')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  private formatTitles(titles: string[]): string {
    return titles.length === 0
      ? this.appName
      : `${this.appName} | ${titles.join(' | ')}`;
  }

  private isRepeatingTitlesSequentially(titles: string[]): boolean {
    return titles.length > 1 && titles[titles.length - 1] === titles[titles.length - 2]
  }
}
