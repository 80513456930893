import {Component, OnInit} from '@angular/core';
import {TableColumn, TableData} from "../../shared/model/tableData.model";
import {RegisterService} from "../../core/service/register.service";
import {IRetryData} from "../../shared/model/retry-data.model";
import {MessageService} from "../../core/service/message.service";
import {BehaviorSubject, delay, finalize, tap} from "rxjs";
import {IModalData, ModalService} from "../../shared/components/modal/modal.service";
import {
  ConfirmActionModalComponent
} from "../../shared/components/modal/modal-views/confirm-action-modal/confirm-action-modal.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {GlobalConstants} from "../../shared/constants/global.constants";
import {take} from "rxjs/operators";

const BANNER_DOWN_ERROR = 'Registration will be processed soon.';

@Component({
    selector: 'app-failed-submissions',
    templateUrl: './failed-submissions.component.html',
    styleUrl: './failed-submissions.component.scss',
    standalone: false
})
export class FailedSubmissionsComponent implements OnInit {
  public tableColumns!: TableColumn[];
  public failedTableData!: TableData[];
  public pendingTableData!: TableData[];
  public failedRegistrations!: IRetryData[];
  public pendingRegistrations!: IRetryData[];
  public isProcessing$ = new BehaviorSubject<boolean>(false);


  constructor(private registerService: RegisterService, private messageService: MessageService, private modalService: ModalService) {}

  ngOnInit() {
    this.tableColumns = [
      { header: 'date', field: 'date', sortDirection: '', dataType: 'string' },
      { header: 'error type', field: 'errorType', sortDirection: '', dataType: 'string' },
      { header: 'student id', field: 'studentId', sortDirection: '', dataType: 'string' },
      { header: 'student name', field: 'studentName', sortDirection: '', dataType: 'string' },
      { header: 'term', field: 'term', sortDirection: '', dataType: 'string' },
      { header: '', field: 'resolution', sortDirection: '', dataType: undefined }
    ]

    this.fetchPendingSubmissions();
    this.fetchFailedSubmissions();
  }

  public retrySubmission($event: string) {
    if (this.isProcessing$.value) { return; }

    const failedRegistration: IRetryData | undefined = this.failedRegistrations.find((retry: IRetryData) => retry.serviceRegistrationId === $event)
    if (failedRegistration) {
      this.isProcessing$.next(true);
      this.registerService.retryFailedRegistration(failedRegistration).pipe(
        take(1),
        delay(500),
        finalize(() => { this.isProcessing$.next(false) }),
      ).subscribe({
        next: (retryData: IRetryData) => {
          this.messageService.showMessage(`You have successfully registered ${failedRegistration.studentName} for ${failedRegistration.opportunityName}`);
          this.failedTableData = this.failedTableData.filter((data: TableData) => data.id !== failedRegistration.serviceRegistrationId);
        }, error: (error: {error: {error: string}}) => {
          this.messageService.showMessage(error.error.error);
          if (error.error.error === BANNER_DOWN_ERROR) {
            this.fetchFailedSubmissions();
            this.fetchPendingSubmissions();
          }
        }
      })
    }
  }

  public resolveWithoutRegistration($event: any) {
    const failedRegistration: IRetryData | undefined = this.failedRegistrations.find((retry: IRetryData) => retry.serviceRegistrationId === $event)
    if (!failedRegistration) { return; }

    const data: IModalData = {
      action: GlobalConstants.CONFIRM_REMOVE_FAILED_SUBMISSION
    }

    // @ts-ignore
    const confirmationModal: NgbModalRef = this.modalService.open(ConfirmActionModalComponent, data);

    confirmationModal.componentInstance!.confirmRemoveFailedSubmissionEvent.subscribe((confirmed: boolean) => {
      this.registerService.removeFailedRegistration(failedRegistration).pipe(
        tap((response) => {
          this.failedTableData = this.failedTableData.filter((data: TableData) => data.id !== $event);
          this.messageService.showMessage(response.message);
          confirmationModal.dismiss();
        })
      ).subscribe();
    })
  }

  public retryAllPendingSubmissions() {
    if (this.isProcessing$.value) { return; }

    this.isProcessing$.next(true);

    this.registerService.retryRegistration().pipe(
      take(1),
      delay(3000),
      finalize(() => {
        this.isProcessing$.next(false)

        this.fetchPendingSubmissions();
        this.fetchFailedSubmissions();
      }),
    ).subscribe(() => {
      this.messageService.showMessage('All pending submissions have been retried, failures should show above in the failure queue. If banner is not responsive, registrations will continue to show in the pending queue.');
    })
  }

  public fetchPendingSubmissions() {
    this.registerService.getPendingRegistrations().pipe(take(1)).subscribe((data: IRetryData[]) => {
      this.pendingRegistrations = data;
      this.pendingTableData = [];

      this.pendingTableData = data.map((retry: IRetryData) => {
        return {
          id: retry.serviceRegistrationId,
          date: retry.activityDate,
          studentId: retry.studentId,
          studentName: retry.studentName,
          term: retry.termCode,
          errorType: retry.errorMessage,
          isSelected: false,
          isExpanded: false
        };
      });
    })
  }

  public fetchFailedSubmissions() {
    this.registerService.getFailedRegistrations().pipe(take(1)).subscribe((data: IRetryData[]) => {
      this.failedRegistrations = data;
      this.failedTableData = [];

      this.failedTableData = data.map((retry: IRetryData) => {
        return {
          id: retry.serviceRegistrationId,
          date: retry.activityDate,
          studentId: retry.studentId,
          studentName: retry.studentName,
          term: retry.termCode,
          errorType: retry.errorMessage,
          isSelected: false,
          isExpanded: false
        };
      });
    });
  }
}
