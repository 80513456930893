import {ScrollingModule} from '@angular/cdk/scrolling';
import {isDevMode, NgModule} from '@angular/core';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CommonModule, DatePipe, JsonPipe, NgOptimizedImage} from '@angular/common';
import {InjectorModule} from "./core/service/injector/injector.module";
import {AuthenticationInterceptorService} from "./core/service/azure/authentication-interceptor.service";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {authReducers} from './core/store/auth/auth.reducers';
import {GetUserInfoEffect} from './core/store/auth/effects/getUserInfo.effect';
import {ImpersonateEffect} from './core/store/auth/effects/impersonate.effect';
import { GetCurrentSupervisorEffect } from './core/store/opportunity-config/effects/getCurrentSupervisor.effect';
import {
  GetOpportunityAnnouncementsEffect
} from './core/store/opportunity-config/effects/getOpportunityAnnouncements.effect';
import {GetOpportunityEffect} from './core/store/opportunity/effects/getOpportunity.effect';
import {opportunityReducers} from './core/store/opportunity/opportunity.reducers';
import {GetOrganizationEffect} from './core/store/organization/effects/getOrganization.effect';
import {organizationReducers} from './core/store/organization/organization.reducers';
import {ServeRouterStateSerializer} from './core/store/router/serve-router-state-serializer';
import { StudentEvaluationEffect } from './core/store/student-evaluation/effects/student-evaluation.effects';
import { studentEvaluationReducers } from './core/store/student-evaluation/student-evaluation.reducers';
import {GetSupervisorEffect} from './core/store/supervisor/effects/getSupervisor.effect';
import {supervisorReducers} from './core/store/supervisor/supervisor.reducers';
import {AdminSettingsComponent} from './feature/admin-settings/admin-settings.component';
import {EvaluationComponent} from './feature/evaluation/evaluation.component';
import {EvaluationModule} from './feature/evaluation/evaluation.module';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {StudentLandingPageComponent} from './landing-page/student-landing-page/student-landing-page.component';
import {HeaderBarComponent} from './routing-hub/header-bar/header-bar.component';
import {UserDropdownComponent} from './routing-hub/user-dropdown/user-dropdown.component';
import {MY_FORMATS} from './shared/constants/datepicker-formats.constants';
import {TermPipe} from './shared/pipes/term.pipe';
import {SharedModule} from './shared/shared.module';
import {SideBarComponent} from './routing-hub/side-bar/side-bar.component';
import {SupervisorLandingPageComponent} from './landing-page/supervisor-landing-page/supervisor-landing-page.component';
import {OrganizationConfigComponent} from './feature/organization-config/organization-config.component';
import {AdminLandingPageComponent} from './landing-page/admin-landing-page/admin-landing-page.component';
import {AdminSideBarComponent} from './routing-hub/admin-side-bar/admin-side-bar.component';
import {routerReducer, RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {UploadsComponent} from './feature/uploads/uploads.component';
import {auditReducers} from "./core/store/audit/audit.reducers";
import {AuditEffect} from "./core/store/audit/effects/audit.effect";
import {searchMapReducers} from "./core/store/search-map/search-map.reducers";
import {SearchMapEffects} from "./core/store/search-map/effects/search-map.effects";
import {regsiterReducers} from './core/store/register/register.reducers';
import {AttemptCSERRegistrationEffect} from './core/store/register/effects/attemptCSERRegister.effects';
import {opportunityConfigReducers} from "./core/store/opportunity-config/opportunity-config.reducers";
import {termEventReducers} from './core/store/term-event/term-event.reducers';
import {TermEventsEffect} from './core/store/term-event/effects/term-events.effects';
import {UserSupervisorEffect} from "./core/store/user-supervisor/effects/user-supervisor.effects";
import {userSupervisorReducers} from "./core/store/user-supervisor/user-supervisor.reducers";
import {MatTooltipModule} from "@angular/material/tooltip";
import {adminReducers} from "./core/store/admin/admin.reducers";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {navigationReducers} from "./core/store/navigation/navigation.reducers";
import {SkipStudentDashboardEffect} from "./core/store/navigation/effects/skipStudentDashboard.effect";
import {AccessRevokedComponent} from "./feature/access-revoked/access-revoked.component";
import {FailedSubmissionsComponent} from "./feature/failed-submissions/failed-submissions.component";

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    StudentLandingPageComponent,
    HeaderBarComponent,
    UserDropdownComponent,
    EvaluationComponent,
    SideBarComponent,
    SupervisorLandingPageComponent,
    OrganizationConfigComponent,
    AdminLandingPageComponent,
    AdminSideBarComponent,
    AdminSettingsComponent,
    UploadsComponent,
    AccessRevokedComponent,
    FailedSubmissionsComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    ScrollingModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    RouterModule,
    NgbModule,
    BrowserAnimationsModule,
    InjectorModule,
    MatSnackBarModule,
    MatProgressBarModule,
    StoreModule.forRoot({ router: routerReducer }, {}),
    StoreRouterConnectingModule.forRoot({ serializer: ServeRouterStateSerializer }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
    EffectsModule.forRoot([
      AuditEffect,
      GetUserInfoEffect,
      GetOpportunityEffect,
      GetOrganizationEffect,
      GetSupervisorEffect,
      ImpersonateEffect,
      SearchMapEffects,
      SkipStudentDashboardEffect,
      AttemptCSERRegistrationEffect,
      TermEventsEffect,
      UserSupervisorEffect,
      GetCurrentSupervisorEffect,
      GetOpportunityAnnouncementsEffect,
      StudentEvaluationEffect
    ]),
    StoreModule.forFeature('admin', adminReducers),
    StoreModule.forFeature('audit', auditReducers),
    StoreModule.forFeature('auth', authReducers),
    StoreModule.forFeature('opp', opportunityReducers),
    StoreModule.forFeature('org', organizationReducers),
    StoreModule.forFeature('searchOpportunity', searchMapReducers),
    StoreModule.forFeature('sup', supervisorReducers),
    StoreModule.forFeature('register', regsiterReducers),
    StoreModule.forFeature('opportunityConfig', opportunityConfigReducers),
    StoreModule.forFeature('termEvents', termEventReducers),
    StoreModule.forFeature('user-supervisor', userSupervisorReducers),
    StoreModule.forFeature('navigation', navigationReducers),
    StoreModule.forFeature('student-evaluation', studentEvaluationReducers),
    SharedModule,
    BsDropdownModule,
    NgOptimizedImage,
    MatStepperModule,
    EvaluationModule,
    MatTooltipModule
  ],
  providers: [
    { provide: JsonPipe },
    { provide: DatePipe },
    { provide: TermPipe },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptorService, multi: true },
    { provide: RouterStateSerializer, useClass: ServeRouterStateSerializer },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }
