<header>
  <div class="large-bold-text">Failed Submissions</div>
</header>
<section role="region" aria-label="Failed Submissions">
  @if(failedTableData?.length == 0) {
    <div class="no-data medium-bold-text">
      No failed submissions are currently in the queue.
    </div>
  } @else {
    <app-dynamic-table-widget [columns]="tableColumns" [data]="failedTableData" [isFailedSubmission]="true"
                              [hasManualResolution]="true" (manualResolutionEvent)="resolveWithoutRegistration($event)" (retrySubmissionEvent)="retrySubmission($event)"></app-dynamic-table-widget>
  }
  @if (isProcessing$ | async) { <app-spinner></app-spinner> }
</section>
<header id="pending-header">
  <div class="large-bold-text">Pending Submissions</div>
  <button class="custom-button-small-primary-dark-blue align-items-center" (click)="retryAllPendingSubmissions()">Resubmit Pending</button>
</header>
<section role="region" aria-label="Pending Submissions">
  @if(pendingTableData?.length == 0) {
    <div class="no-data medium-bold-text">
      No pending submissions are currently in the queue.
    </div>
  } @else {
    <app-dynamic-table-widget [columns]="tableColumns" [data]="pendingTableData"></app-dynamic-table-widget>
  }
</section>
