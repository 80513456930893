import {Component, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';

@Component({
    selector: 'app-past-evaluation-stepper',
    templateUrl: './past-evaluation-stepper.component.html',
    styleUrls: ['./past-evaluation-stepper.component.scss'],
    standalone: false
})
export class PastEvaluationStepperComponent {

  @ViewChild(MatStepper)
  public stepper!: MatStepper

  constructor() { }

  submitForm() {

  }
}
