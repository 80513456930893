import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, of, Subject, takeUntil} from 'rxjs';
import {AuthService} from '../../core/service/auth.service';
import {UserService} from '../../core/service/user.service';
import {AuditService} from "../../core/service/audit.service";

@Component({
    selector: 'app-user-dropdown',
    templateUrl: './user-dropdown.component.html',
    styleUrls: ['./user-dropdown.component.scss'],
    standalone: false
})
export class UserDropdownComponent implements OnInit, AfterViewChecked, OnDestroy {
  public userName$!: Observable<string | undefined>;
  public isAdmin$!: Observable<boolean> | undefined;
  public canImpersonate$!: Observable<boolean> | undefined;
  public isSupervisor$!: Observable<boolean> | undefined;
  public needsCSER100$: Observable<boolean> = of(false);
  public modalOpen: boolean = false;

  public endSubscription$ = new Subject();

  private readonly MAX_SCREEN_WIDTH: number = 825;

  @Input()
  public creditProgressText: string = '';

  constructor(private auditService: AuditService,
              private authService: AuthService,
              private userService: UserService,
              private elementRef: ElementRef) { }

  @Output()
  canShowUserIcon$: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkScreenWidth() {
    this.determineShowUserIcon();
  }

  ngOnInit() {
    this.userName$ = this.authService.getCurrentUserDisplayName().pipe(takeUntil(this.endSubscription$));
    this.isAdmin$ = this.authService.isAdmin().pipe(takeUntil(this.endSubscription$));
    this.isSupervisor$ = this.authService.isSupervisor().pipe(takeUntil(this.endSubscription$));
    this.canImpersonate$ = this.authService.canImpersonate().pipe(takeUntil(this.endSubscription$));
    this.needsCSER100$ = this.auditService.needsCSER100().pipe(takeUntil(this.endSubscription$));

    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth.bind(this));
    this.determineShowUserIcon();
  }

  ngAfterViewChecked() {
    this.setHostElementOffsetWidth();
    this.determineShowUserIcon();
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public logOut(): void {
    this.userService.signOut();
  }

  public openModal() {
    this.modalOpen = true;
  }

  public onModalClose() {
    this.modalOpen = false;
  }

  private setHostElementOffsetWidth(): void {
    const hostElement = this.elementRef.nativeElement;
    hostElement.style.setProperty('--host-offset-width', `${hostElement.offsetWidth}px`);
  }

  private determineShowUserIcon(): void {
    const screenWidth: number = window.innerWidth;
    this.canShowUserIcon$.emit(screenWidth <= this.MAX_SCREEN_WIDTH);
  }

}
