import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {select, Store} from '@ngrx/store';
import {delay, exhaustMap, filter, map, mergeMap, of, take, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthRepository} from '../../../repository/auth.repository';
import {
  getUserInfoAction,
  getUserInfoFailureAction,
  getUserInfoLoadedAction,
  getUserInfoSuccessAction,
  updateUserInfoAction,
  updateUserInfoFailureAction,
  updateUserInfoSuccessAction,
} from '../auth.actions';
import {getUserInfoSelector} from '../auth.selectors';
import {Router} from "@angular/router";
import {SupervisorRepository} from '../../../repository/supervisor.repository';
import {Roles} from "../../../../shared/constants/roles.constants";

@Injectable()
export class GetUserInfoEffect {

  private readonly ADMIN_BASE_ROUTE = '/admin';

  constructor(private actions$: Actions,
    private router: Router,
    private store: Store,
    private authRepo: AuthRepository,
    private supervisorRepo: SupervisorRepository) {
  }

  // noinspection TypeScriptValidateTypes
  getUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(getUserInfoAction),
    concatLatestFrom(() => this.store.pipe(select(getUserInfoSelector))),
    filter(([, userInfo]) => !userInfo),
    exhaustMap(() => {
      return this.authRepo.getUserInfo().pipe(
        map(response => getUserInfoSuccessAction({ data: response })),
        catchError((errorResponse: HttpErrorResponse) => of(getUserInfoFailureAction({ error: errorResponse })))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  updateUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(updateUserInfoAction),
    mergeMap((action) => {
      return this.authRepo.updateUserInfo(action.data).pipe(
        map(response => updateUserInfoSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(updateUserInfoFailureAction({error: errorResponse, id: action.data.id})))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  loadingComplete$ = createEffect(() => this.actions$.pipe(
    ofType(getUserInfoSuccessAction),
    delay(750), //this triggers at the same(?) time as the redirect, so give it a moment to finish before hiding the loading indicator
    exhaustMap(() => {
      return of(getUserInfoLoadedAction());
    })
  ));

  // noinspection TypeScriptValidateTypes
  userRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(getUserInfoSuccessAction),
    tap((userResponse) => {
      let hasSupervisor = false;
      this.supervisorRepo.getCurrentSupervisor().pipe(take(1)).subscribe( {
        next: (supervisor) => {
          hasSupervisor = true;
          if (userResponse.data.userRoles.includes(Roles.ADMIN) && userResponse.data.userRoles.includes(Roles.SUPERVISOR) &&
            !supervisor?.isContractSigned && !userResponse.data.isImpersonating) {
            this.router.navigateByUrl('/supervisor/supervisor-contract')
          } else if (userResponse.data.userRoles.includes(Roles.ADMIN) && this.isBaseRoute() && !this.router.url.startsWith(this.ADMIN_BASE_ROUTE)) {
            this.router.navigateByUrl(this.ADMIN_BASE_ROUTE)
          } else if (userResponse.data.userRoles.includes(Roles.SUPERVISOR) && this.isBaseRoute()) {
            if (supervisor?.isContractSigned || userResponse.data.isImpersonating) {
              this.router.navigateByUrl('/supervisor')
            } else {
              this.router.navigateByUrl('/supervisor/supervisor-contract')
            }
          }
        }, complete: () => {
          // Handle this here, because getCurrentSupervisor will return a 404 for anyone that isn't a supervisor.
          // The interceptor will swallow that, so we can't use an error method and have to check the state when the
          // Observable completes.
          if (!hasSupervisor) {
            if (userResponse.data.userRoles.includes(Roles.ADMIN) && this.isBaseRoute() && !this.router.url.startsWith(this.ADMIN_BASE_ROUTE)) {
              this.router.navigateByUrl(this.ADMIN_BASE_ROUTE)
            }
          }
        }
      });
    })
  ), { dispatch: false });

  private isBaseRoute() : boolean {
    return this.router.url == '/' || this.router.url == '/student-dashboard';
  }
}
