import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
    selector: 'app-evaluation-review',
    templateUrl: './evaluation-review.component.html',
    styleUrls: ['./evaluation-review.component.scss'],
    standalone: false
})
export class EvaluationReviewComponent implements OnInit {
  @Input()
  public service!: IServiceRegistration;

  @Output()
  public worklogEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public reviewComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    this.reviewComplete.emit(this.service.hoursServed >= 20);
  }

  public emitWorklogEvent() {
    this.worklogEvent.emit();
  }
}
