import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceRegistrationRepository } from '../../../repository/service-registration.repository';
import {
    addEvaluationsAction,
    addEvaluationsFailureAction,
    addEvaluationsSuccessAction,
    getEvaluationsAction, getEvaluationsFailureAction, getEvaluationsSuccessAction,
    updateEvaluationsAction,
    updateEvaluationsFailureAction,
    updateEvaluationsSuccessAction
} from '../student-evaluation.actions';



@Injectable()
export class StudentEvaluationEffect {
  constructor(
    private actions$: Actions,
    private serviceRegistrationRepo: ServiceRegistrationRepository
  ) { }

    // noinspection TypeScriptValidateTypes
    getEvaluations$ = createEffect(() => this.actions$.pipe(
        ofType(getEvaluationsAction),
        mergeMap(action => {
            return this.serviceRegistrationRepo.getEvaluations(action.serviceId).pipe(
                map(response => getEvaluationsSuccessAction({evaluations: response})),
                catchError((errorResponse: HttpErrorResponse) => of(getEvaluationsFailureAction({error: errorResponse})))
            )
        })
    ));

    // noinspection TypeScriptValidateTypes
    addEvaluations$ = createEffect(() => this.actions$.pipe(
        ofType(addEvaluationsAction),
        mergeMap(action => {
          return this.serviceRegistrationRepo.addEvaluations(action.serviceId, action.newEvaluations).pipe(
            map(response => addEvaluationsSuccessAction({evaluations: response})),
            catchError((errorResponse: HttpErrorResponse) => of(addEvaluationsFailureAction({error: errorResponse})))
          )
        })
    ));

    // noinspection TypeScriptValidateTypes
    updateEvaluations$ = createEffect(() => this.actions$.pipe(
        ofType(updateEvaluationsAction),
        mergeMap(action => {
          return this.serviceRegistrationRepo.updateEvaluation(action.serviceId, action.evaluation).pipe(
            map(response => updateEvaluationsSuccessAction({evaluation: response})),
            catchError((errorResponse: HttpErrorResponse) => of(updateEvaluationsFailureAction({error: errorResponse})))
          )
        })
    ));
}
