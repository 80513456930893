import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Subject, takeUntil} from 'rxjs';
import {take} from 'rxjs/operators';
import {ServiceRegistrationService} from '../../../../core/service/service-registration.service';
import {setStudentReflectionStepCompletionAction} from '../../../../core/store/student-evaluation/student-evaluation.actions';
import {getStudentReflectionEvaluationsSelector} from '../../../../core/store/student-evaluation/student-evaluation.selectors';
import {EvaluationFormControls} from '../../../../shared/model/evaluation-form-control.model';
import {EvaluationQuestion, EvaluationQuestionSection, EvaluationQuestionType, IEvaluation} from '../../../../shared/model/evaluation-model';
import {IServiceRegistration} from '../../../../shared/model/service-registration.model';

@Component({
    selector: 'app-evaluation-reflection',
    templateUrl: './evaluation-reflection.component.html',
    styleUrls: ['./evaluation-reflection.component.scss'],
    standalone: false
})
export class EvaluationReflectionComponent implements OnInit, OnDestroy{
  public isMobile: boolean = window.innerWidth < 1024;

  @Input()
  public service!: IServiceRegistration;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth < 1024;
  }
  public evaluations: IEvaluation[] = [];
  public reflectionForm!: FormGroup;
  public formControls: EvaluationFormControls = {};

  public evaluationQuestions: EvaluationQuestion[] = [
    { id: 'ST_REF_1', text: 'This CSER challenged me to develop stronger moral/spiritual character.', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.REFLECTION, order: '1' },
    { id: 'ST_REF_2', text: 'This CSER allowed me to make an important impact in the community.', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.REFLECTION, order: '2' },
    { id: 'ST_REF_3', text: 'This CSER is directly related to my academic major or minor.', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.REFLECTION, order: '3' },
    { id: 'ST_REF_4', text: 'Through this CSER, I gained skills that will help me in my future career.', type: EvaluationQuestionType.STUDENT, section: EvaluationQuestionSection.REFLECTION, order: '4' }
  ];

  private endSubscription$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private serviceRegistrationService: ServiceRegistrationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.evaluationQuestions.forEach(question => {
      this.formControls[question.id] = [null, [Validators.required]];
    });

    this.reflectionForm = this.fb.group(this.formControls);
    this.watchFormValidity();
    this.getEvaluations();
  }

  ngOnDestroy() {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public getEvaluations() {
    this.store.select(getStudentReflectionEvaluationsSelector).pipe(
      take(1),
    ).subscribe(evaluations => {
      this.evaluations = evaluations;

      evaluations.forEach(evaluation => {
        if(evaluation.question.order === '1') {
          this.reflectionForm.get('ST_REF_1')?.setValue(evaluation.response);
        } else if (evaluation.question.order === '2') {
          this.reflectionForm.get('ST_REF_2')?.setValue(evaluation.response);
        } else if (evaluation.question.order === '3') {
          this.reflectionForm.get('ST_REF_3')?.setValue(evaluation.response);
        } else if (evaluation.question.order === '4') {
          this.reflectionForm.get('ST_REF_4')?.setValue(evaluation.response);
        }
      });
    })
  }

  public watchFormValidity() {
    this.reflectionForm.valueChanges.pipe(takeUntil(this.endSubscription$)).subscribe(() => {
      this.store.dispatch(setStudentReflectionStepCompletionAction({isComplete: this.reflectionForm.valid}));
    })
  }

  public submitEvaluations() {
    if (this.evaluations.length > 0) {
      const updatedEvaluations: IEvaluation[] = [];

      Object.keys(this.reflectionForm.controls).forEach((question, index) => {
        const value = this.reflectionForm.get(question)?.value;
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;

        const existingEvaluation = this.evaluations.find(evaluation => evaluation.question.order === questionUsed.order);

        if (existingEvaluation && existingEvaluation.response !== value) {
          updatedEvaluations.push({
            ...existingEvaluation,
            response: value
          });
        }
      });

      updatedEvaluations.forEach(evaluation => {
        this.serviceRegistrationService.updateEvaluationsThroughState(this.service.id, evaluation);
      });
    } else {
      const newEvaluations: Partial<IEvaluation>[] = Object.keys(this.reflectionForm.controls).map((question, index) => {
        const questionUsed = this.evaluationQuestions.find(evalQuestion => evalQuestion.id === question)!;
        const value = this.reflectionForm.get(question)?.value;

        return {
          serviceId: this.service.id,
          response: value,
          question: questionUsed
        };
      });

      this.serviceRegistrationService.addEvaluationsThroughState(this.service.id, newEvaluations);
    }
  }
}
