<div id="header" class="header-wrapper f-row" #header>
  <div class="header-info f-col">
    <div class="x-large-x-bold-text">Volunteer Evaluation</div>
    @if(!!opportunity) {
      <app-organization-info [organizationId]="opportunity.organizationId" [opportunity]="opportunity" [viewType]="'opportunity'"></app-organization-info>
    }
  </div>
  <mat-stepper class="stepper f-row" [linear]="true" labelPosition="bottom" color="primary" #stepper>
    <mat-step label="Reflection" [completed]="reflectionComplete"></mat-step>
    <mat-step label="Opportunity" [completed]="opportunityComplete"></mat-step>
    <mat-step label="Experience" [completed]="experienceComplete"></mat-step>
    <mat-step label="Review" [completed]="reviewComplete"></mat-step>
  </mat-stepper>
</div>

<div [ngSwitch]="stepper.selected?.label" class="switch-page f-col">
  <div *ngSwitchCase="'Reflection'">
    <app-evaluation-reflection *ngIf="serviceRegistration && !isLoadingEvaluations" [service]="serviceRegistration" class="f-col" #reflectionComponent></app-evaluation-reflection>
  </div>
  <div *ngSwitchCase="'Opportunity'">
    <app-evaluation-opportunity *ngIf="serviceRegistration && !isLoadingEvaluations" [service]="serviceRegistration" class="f-col" #opportunityComponent></app-evaluation-opportunity>
  </div>
  <div *ngSwitchCase="'Experience'">
    <app-evaluation-experience *ngIf="serviceRegistration && !isLoadingEvaluations" [service]="serviceRegistration" [opportunity]="opportunity" class="f-col" #experienceComponent></app-evaluation-experience>
  </div>
  <div *ngSwitchCase="'Review'">
    <app-evaluation-review *ngIf="serviceRegistration && !isLoadingEvaluations" [service]="serviceRegistration" (reviewComplete)="evalEvent($event, 'review')" (worklogEvent)="getServiceRegistration()" class="f-col" #reviewComponent></app-evaluation-review>
  </div>
</div>

<div class="buttons-wrapper f-row">
  <button *ngIf="stepper.selected?.label != 'Reflection'" class="previous-button custom-button-large-secondary" (click)="stepper.previous(); header.scrollIntoView();">
    <app-icon icon="arrow-left" class="left-icon"></app-icon>
    Previous
  </button>
  <div *ngIf="stepper.selected?.label == 'Reflection'"></div>
  <button class="custom-button-large-primary" *ngIf="stepper.selected?.label != 'Review'" [disabled]="!canProceedStepper(stepper.selectedIndex)" (click)="stepper.next(); submitAnswers(stepper.selectedIndex); header.scrollIntoView();">
    Continue
    <app-icon icon="arrow-right" class="right-icon"></app-icon>
  </button>
  <button class="custom-button-large-primary" *ngIf="stepper.selected?.label == 'Review'" [matTooltip]="serviceRegistration.hoursServed < 20 ? 'You must have at least 20 hours logged to submit an evaluation!' : ''" [disabled]="!canProceedStepper(stepper.selectedIndex) || serviceRegistration.hoursServed < 20" (click)="submitEvaluation()">
    Submit Evaluation
  </button>
</div>
