<nav class="f-row">
  <app-icon class="logo-icon" icon="logo"></app-icon>
  <div class="spacer"></div>
  <ul class="nav-list p-0">
    <li class="list-item f-row" id="home" routerLinkActive="tabActive">
      <a class="item f-row" role="tab" [routerLink]="['student-dashboard']" aria-current="page">
        <app-icon icon="house" class="small-icon"></app-icon>
        <span class="text small-medium-text">Home</span>
      </a>
    </li>
    <li class="list-item f-row" id="opportunity" routerLinkActive="tabActive">
      <a class="item f-row" role="tab" [routerLink]="['search-opportunity']" aria-current="page">
        <app-icon icon="search" class="small-icon"></app-icon>
        <span class="text small-medium-text">Search</span>
      </a>
    </li>
    <li class="right-items">
      @if (showCreditProgressButton) {
        <button class="custom-button-small-secondary" id="credit-progress" attr.aria-label="{{ getCreditProgressText() }} press enter or click to get more details."
          [routerLink]="['/', 'audit']">
          <app-icon icon="pie-chart" class="x-small-icon"></app-icon>
          <span class="progress-text">{{ getCreditProgressText() }}</span>
        </button>
      }
      <app-user-dropdown [creditProgressText]="getCreditProgressText()"
                         (canShowUserIcon$)="canShowCreditProgress($event)"></app-user-dropdown>
    </li>
  </ul>
</nav>
