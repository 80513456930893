import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {MemoizedSelector, select, Store} from '@ngrx/store';
import {filter, Observable, Subject, takeUntil, tap} from 'rxjs';
import {OpportunityService} from '../../../core/service/opportunity.service';
import {ServiceRegistrationService} from '../../../core/service/service-registration.service';
import {selectServiceId,} from '../../../core/store/router/router.selectors';
import {
  clearStudentEvaluationAction,
  getEvaluationsAction
} from '../../../core/store/student-evaluation/student-evaluation.actions';
import {
  getStudentEvaluationStepNumberSelector, isLoadingEvaluationsSelector,
  isStudentExperienceStepCompleteSelector,
  isStudentOpportunityStepCompleteSelector,
  isStudentReflectionStepCompleteSelector
} from '../../../core/store/student-evaluation/student-evaluation.selectors';
import {IOpportunity} from '../../../shared/model/opportunity.model';
import {IServiceRegistration, ServiceEvalStatus} from '../../../shared/model/service-registration.model';
import {EvaluationExperienceComponent} from './evaluation-experience/evaluation-experience.component';
import {EvaluationOpportunityComponent} from './evaluation-opportunity/evaluation-opportunity.component';
import {EvaluationReflectionComponent} from './evaluation-reflection/evaluation-reflection.component';

@Component({
    selector: 'app-evaluation-stepper',
    templateUrl: './evaluation-stepper.component.html',
    styleUrls: ['./evaluation-stepper.component.scss'],
    standalone: false
})
export class EvaluationStepperComponent implements OnInit, OnDestroy {
  @ViewChild('stepper')
  stepper!: MatStepper;

  public oppId!: string;
  public opportunity!: IOpportunity;
  public serviceRegistration!: IServiceRegistration;

  public isStepperInitialized: boolean = false;
  public isLoadingEvaluations: boolean = false;
  public reflectionComplete: boolean = false;
  public opportunityComplete: boolean = false;
  public experienceComplete: boolean = false;
  public reviewComplete: boolean = false;
  public currentStep!: number;

  @ViewChild('reflectionComponent') reflectionComponent!: EvaluationReflectionComponent;
  @ViewChild('opportunityComponent') opportunityComponent!: EvaluationOpportunityComponent;
  @ViewChild('experienceComponent') experienceComponent!: EvaluationExperienceComponent;

  private endSubscription$ = new Subject();

  constructor(
    private router: Router,
    private opportunityService: OpportunityService,
    private store: Store,
    private serviceRegService: ServiceRegistrationService) {}

  ngOnInit() {
    this.setupStepperSubscriptions();

    this.store.pipe(
      select(selectServiceId),
      filter(Boolean),
    ).subscribe(serviceId => {
      this.serviceRegService.getServiceRegistration(serviceId).subscribe(serviceReg => {
        this.serviceRegistration = serviceReg;
        this.store.dispatch(getEvaluationsAction({serviceId: serviceId}));
        this.opportunityService.getOpportunity(serviceReg.oppId).subscribe(opp => this.opportunity = opp);
      });
    })
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearStudentEvaluationAction());

    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  public setupStepperSubscriptions() {
    this.store.select(isLoadingEvaluationsSelector).pipe(takeUntil(this.endSubscription$)).subscribe(value => this.isLoadingEvaluations = value);
    this.store.select(getStudentEvaluationStepNumberSelector).pipe(takeUntil(this.endSubscription$)).subscribe(value => this.currentStep = value);
    this.setupCompleteSubscriptions(isStudentReflectionStepCompleteSelector).pipe(takeUntil(this.endSubscription$)).subscribe(value => this.reflectionComplete = value);
    this.setupCompleteSubscriptions(isStudentOpportunityStepCompleteSelector).pipe(takeUntil(this.endSubscription$)).subscribe(value => this.opportunityComplete = value);
    this.setupCompleteSubscriptions(isStudentExperienceStepCompleteSelector).pipe(takeUntil(this.endSubscription$)).subscribe(value => this.experienceComplete = value);
    this.isStepperInitialized = true;
  }

  public setupCompleteSubscriptions(selector: MemoizedSelector<object, boolean>): Observable<boolean> {
    return this.store.pipe(
      select(selector),
      tap(isComplete => {
        if (isComplete && !this.isStepperInitialized) {
          setTimeout(() => this.stepper.next(), 500)
        }
      }));
  }

  public getServiceRegistration() {
    this.serviceRegService.getServiceRegistration(this.serviceRegistration.id).subscribe(serviceReg => {
      this.serviceRegistration = serviceReg;
      this.evalEvent(this.serviceRegistration.hoursServed >= 20, 'review');
    })
  }

  public submitEvaluation() {
    if(this.opportunityComplete && this.experienceComplete && this.reflectionComplete && this.serviceRegistration.hoursServed >= 20) {
      let updatedService: IServiceRegistration = {
        ...this.serviceRegistration,
        studentEvalStatus: ServiceEvalStatus.COMPLETE
      }

      this.serviceRegService.updateServiceRegistration(updatedService).subscribe(() => {
        this.router.navigate(['/', 'evaluation', 'feedback']);
      })
    }
  }

  public canProceedStepper(index: number) {
    switch (index) {
      case 0:
        return this.reflectionComplete;
      case 1:
        return this.opportunityComplete;
      case 2:
        return this.experienceComplete;
      case 3:
        return this.reviewComplete;
      default:
        return true;
    }
  }

  public evalEvent(event: boolean, evalName: string) {
      if(evalName === 'review') {
      this.reviewComplete = this.serviceRegistration.hoursServed >= 20;
    }
  }

  public submitAnswers(index: number) {
    switch (index) {
      case 1:
        this.reflectionComponent.submitEvaluations();
        break;
      case 2:
        this.opportunityComponent.submitEvaluations();
        break;
      case 3:
        this.experienceComponent.submitEvaluations();
        break;
    }
  }
}
