import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, Subject, switchMap, takeUntil} from 'rxjs';
import {ServiceRegistrationService} from '../../../core/service/service-registration.service';
import {selectServiceId} from '../../../core/store/router/router.selectors';
import {IEvaluation} from '../../../shared/model/evaluation-model';
import {
  Answer,
  IServiceRegistration,
  ServiceEvalStatus,
  ServiceGrade,
  ServiceStatus
} from '../../../shared/model/service-registration.model';
import {ServiceEvaluationFutureComponent} from './service-evaluation-future/service-evaluation-future.component';
import {ServiceEvaluationGradeComponent} from './service-evaluation-grade/service-evaluation-grade.component';
import {
  ServiceEvaluationImprovementComponent
} from './service-evaluation-improvement/service-evaluation-improvement.component';
import {
  ServiceEvaluationPerformanceComponent
} from './service-evaluation-performance/service-evaluation-performance.component';
import {attemptCSERRegisterAction, clearRegistrationDataAction} from '../../../core/store/register/register.actions';
import {MessageService} from '../../../core/service/message.service';
import {RegisterService} from '../../../core/service/register.service';
import {ServiceEvaluationHoursComponent} from "./service-evaluation-hours/service-evaluation-hours.component";

@Component({
    selector: 'app-service-evaluation-stepper',
    templateUrl: './service-evaluation-stepper.component.html',
    styleUrls: ['./service-evaluation-stepper.component.scss'],
    standalone: false
})
export class ServiceEvaluationStepperComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void>;
  public service!: IServiceRegistration;
  public grade: ServiceGrade = ServiceGrade.A;
  public evaluations: Partial<IEvaluation>[] = [];
  public hoursComplete: boolean = false;
  public performanceComplete: boolean = false;
  public futureComplete: boolean = false;
  public improvementComplete: boolean = false;
  public gradeComplete: boolean = false;
  public registrationResult: string = '';

  public isRegistering$!: Observable<boolean>;

  private readonly BANNER_DOWN_MESSAGE = 'Registration will be processed soon.'

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('hoursComponent') hoursComponent!: ServiceEvaluationHoursComponent;
  @ViewChild('performanceComponent') performanceComponent!: ServiceEvaluationPerformanceComponent;
  @ViewChild('futureComponent') futureComponent!: ServiceEvaluationFutureComponent;
  @ViewChild('improvementComponent') improvementComponent!: ServiceEvaluationImprovementComponent;
  @ViewChild('gradeComponent') gradeComponent!: ServiceEvaluationGradeComponent;

  constructor(private serviceRegistrationService: ServiceRegistrationService,
    private store: Store,
    private router: Router,
    private messageService: MessageService,
    private registerService: RegisterService
  ) {
    this.onDestroy$ = new Subject<void>();
  }

  ngOnInit() {
    this.store.select(selectServiceId).pipe(
      takeUntil(this.onDestroy$),
      switchMap((serviceId: string) => {
        if (!serviceId) {
          return EMPTY;
        }

        return this.serviceRegistrationService.getServiceRegistration(serviceId).pipe(
          switchMap((service: IServiceRegistration) => {
            this.service = service;
            return EMPTY;
          })
        )
      })
    ).subscribe()

    this.isRegistering$ = this.registerService.getIsRegistering();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public submitEvaluation() {
    this.store.dispatch(clearRegistrationDataAction());

    this.gradeComponent.submitEvaluations();

    if (this.hoursComplete && this.performanceComplete && this.futureComplete && this.improvementComplete && this.gradeComplete) {
      let updatedService: IServiceRegistration = {
        ...this.service,
        supervisorEvalStatus: ServiceEvalStatus.COMPLETE,
        grade: this.grade,
        status: this.grade == ServiceGrade.F || this.grade == ServiceGrade.INCOMPLETE ? ServiceStatus.FAILED : ServiceStatus.PASSED
      }

      this.store.dispatch(attemptCSERRegisterAction({ serviceRegistration: updatedService }));

      this.registerService.getRegistrationResult().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
        if (result != null) {
          this.router.navigate(['/', 'supervisor', 'my-opportunities', this.service.oppId]);
        }
      });

      this.registerService.getRegistrationError().pipe(takeUntil(this.onDestroy$)).subscribe(error => {
        if (error != null) {
          this.messageService.showErrorMessage(error);
        }
      })
    }
  }

  public setGrade(value: any) {
    this.grade = value.grade;
  }

  public canProceedStepper(index: number) {
    switch (index) {
      case 0:
        return this.hoursComplete;
      case 1:
        return this.performanceComplete;
      case 2:
        return this.futureComplete;
      case 3:
        return this.improvementComplete;
      case 4:
        return this.gradeComplete;
      default:
        return true;
    }
  }

  public evalHoursEvent(event: any) {
    if (event.answer === Answer.NO) {
      // TODO: send email with event.issues
      this.router.navigate(['/', 'supervisor', 'evaluation-correction'])
    }
  }

  public evalEvent(event: boolean, evalName: string) {
    if (evalName === 'hours') {
      this.hoursComplete = event;
    } else if (evalName === 'performance') {
      this.performanceComplete = event;
    } else if (evalName === 'future') {
      this.futureComplete = event;
    } else if (evalName === 'improvement') {
      this.improvementComplete = event;
    } else if (evalName === 'grade') {
      this.gradeComplete = event;
    }
  }

  public submitAnswers(index: number) {
    switch (index) {
      case 1:
        this.hoursComponent.submitEvaluations();
        break;
      case 2:
        this.performanceComponent.submitEvaluations();
        break;
      case 3:
        this.futureComponent.submitEvaluations();
        break;
      case 4:
        this.improvementComponent.submitEvaluations();
        break;
    }
  }
}
