import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import {AuditService} from '../../core/service/audit.service';
import {AuthService} from '../../core/service/auth.service';

@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss'],
    standalone: false
})
export class HeaderBarComponent implements OnInit, OnDestroy {

  public creditProgress: number = 0;
  public showCreditProgressButton: boolean = false;
  private endSubscriptions$ = new Subject<any>();

  constructor(
    private auditService: AuditService,
    private authService: AuthService) { }

  ngOnInit(): void {

    this.authService.getUserId().pipe(
      takeUntil(this.endSubscriptions$),
      filter(Boolean),
      switchMap(id => this.auditService.getAuditSnapshot(id))
    ).subscribe(audit => {
      this.creditProgress = audit?.totalCredits ?? 0;
    });
  }

  ngOnDestroy() {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.unsubscribe();
  }

  public canShowCreditProgress($event: any): void {
    this.showCreditProgressButton = !$event;
  }

  public getCreditProgressText(): string {
    return `CSER Progress (${this.creditProgress} Credit${this.creditProgress === 1 ? '' : 's'})`;
  }
}
