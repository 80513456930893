import { Component, HostListener, OnInit } from '@angular/core';
import {AuthService} from '../../core/service/auth.service';
import {Observable} from "rxjs";
import { debounce as Debounce } from "../../shared/decorators/debounceDecorator";
import { NavigationEnd, NavigationError, Router } from "@angular/router";

@Component({
    selector: 'app-admin-landing-page',
    templateUrl: './admin-landing-page.component.html',
    styleUrls: ['./admin-landing-page.component.scss'],
    standalone: false
})
export class AdminLandingPageComponent implements OnInit {
  public isImpersonating$!: Observable<boolean | undefined>;
  public isSideBarOpen: boolean | undefined = undefined;

  @HostListener('window:resize', ['$event'])
  @Debounce()
  onResize() {
    this.resetSideBarState();
  }

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.isImpersonating$ = this.authService.isImpersonating();

    this.router.events.subscribe( event => {
      event instanceof NavigationEnd || event instanceof NavigationError
        ? this.closeSideBar(true) : void 0;
    });
  }

  public openSideBar(): void {
    this.isSideBarOpen = true;
  }

  public closeSideBar($shouldClose: boolean): void {
    this.isSideBarOpen === undefined || !$shouldClose ? void 0 : this.isSideBarOpen = false;
  }

  public get sideBarState(): string {
    switch (true) {
      case this.isSideBarOpen === undefined:    return '';
      case this.isSideBarOpen === true:         return '--expanded';
      case this.isSideBarOpen === false:        return '--collapsed';
      default:                                  return '';
    }
  }

  private get isMobile(): boolean {
    return window.innerWidth < 720;
  }

  private resetSideBarState(): void {
    if (this.isMobile) return void 0;
    this.isSideBarOpen = undefined;
  }

}
